@use "sass:map";
/* make the customizations */
$theme-colors: ("info": #ffbc44,
  "primary": #60ff9f,
  "dark": #222222,
  "secondary": #a7a7a7,
  "black": #0f0f0f,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "selection.scss";
@import "resume.scss";
@import "scrollbar.scss";
@import "contact.scss";
@import "projects.scss";


// @import "/src/assets/fonts/fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');





html,
body {
  background-color: map.get($theme-colors, "dark");
  font-family: 'Comfortaa', cursive;
}

a {
  font-weight: bold;
}

a:hover {
  text-decoration: none;
}

.hero {
  font-size: 7.5em;
  font-family: 'Bilbo Swash Caps', cursive;
  line-height: "115px";
}

.blur {
  filter: blur(5px);
  transition: 0.2s ease;
}

.blur:hover {
  filter: blur(0px);
  transition: 0.2s ease;
}

.hover-box-up {
  padding: 5px;
  margin-bottom: -10px;
  background-image: linear-gradient(to right,
      transparent calc(0% - 5px),
      #60ff9f 0%,
      #272727 100%);
  background-size: 0%;
  background-repeat: no-repeat;
  display: flex;
  transition: 0.5s ease;
}

.hover-box-down {
  padding: 5px;
  margin-bottom: 5px;
  background-image: linear-gradient(to right,
      transparent calc(0% - 5px),
      #60ff9f 100%,
      #272727 0%);
  background-size: 0%;
  background-repeat: no-repeat;
  display: flex;
  transition: 0.5s ease;
}

.hover-box-down:hover,
.hover-box-up:hover {
  background-size: 100%;
}


h1 {
  color: map.get($theme-colors, "primary");
}

img.dark-shadow {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 1));
}

.inner-shadow {
  box-shadow: inset 0 0 20px #000000;
}

.social-pill {
  margin-left: -10px;
  padding-left: 25px;
  color: map.get($theme-colors, "primary");
  background-color: map.get($theme-colors, "black");
}

.social-pill:hover {
  color: map.get($theme-colors, "black");
  background-color: map.get($theme-colors, "primary");
}