.projects-marquee {
    margin: 2rem -2rem 0 -5rem !important;
    width: 210vw !important;
    height: 500px !important;
    transform: rotate3d(0.5, -0.866, 0, 20deg) rotateZ(-1deg);
}

@media screen and (min-width: 768px) {
    .projects-marquee {
        transform: rotate(15deg) skew(344deg, 352deg) translate(-25px, 40px);
        margin: 4rem -2rem 0 -6rem !important;
    }
}


.p-shadow {
    box-shadow: 0px 27.5px 40px -17.5px rgb(0 0 0 / 40%), 46.2px 37.5px 40px -17.5px rgb(0 0 0 / 40%)
}

.p-tilt {
    transform: rotate(15deg) skew(344deg, 352deg) translate(-25px, 40px);
}

.p-wall-tilt {
    transform: rotate3d(0.5, -0.866, 0, 20deg) rotateZ(-1deg)
}