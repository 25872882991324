html::-webkit-scrollbar {
    width: 3px;
    background: map.get($theme-colors, "dark");
}

html::-webkit-scrollbar-thumb {
    background: map.get($theme-colors, "primary");
    border-radius: 5px;
}

*::-webkit-scrollbar {
    width: 3px;
    background: map.get($theme-colors, "dark");
}

*::-webkit-scrollbar-thumb {
    background: map.get($theme-colors, "primary");
    border-radius: 5px;
}